import React from "react";
import {
	AlertCircleIcon,
	SbInvoiceCard,
	SbTypography,
	TranslationProvider
} from "@surebase/shared-component-library";
import {
	Card,
	CardActions,
	CardHeader,
	Divider,
	Stack,
	useTheme
} from "@mui/material";
import {Settings} from "../../global/Settings";
import {grey} from "@mui/material/colors";
import {Invoices} from "../../services/invoices/graphql/Invoices";

export interface OutstandingInvoicesCardProps {
	readonly invoices: Invoices.Invoice[];
	readonly width?: string;
	readonly invoicesLink?: string;
	
	onPayClick(invoiceIds: string[]): void;
}

export function OutstandingInvoicesCard(props: OutstandingInvoicesCardProps) {
	const theme = useTheme();
	return <TranslationProvider name={Settings.languageModule}>
		<Card variant={"outlined"}>
			<CardHeader style={{backgroundColor: "#FFFFFF", padding: "16px", gap: "24px"}}
				avatar={(
					<Stack gap={2} alignItems={"center"}
						direction={"row"}>
						<AlertCircleIcon color={"primary"} width={24} height={24}/>
						<SbTypography variant={"cardButtonTitle1"}>
							Outstanding invoices
						</SbTypography>
					</Stack>
				)}>
			</CardHeader>
			<Divider color={grey[300]}/>
			<CardActions style={{padding: "16px", gap: "16px"}}>
				<Stack gap={2} style={{width: "100%"}}>
					{props.invoices.length === 0 && (
						<SbTypography variant={"cardButton2"} color={theme.palette.text.secondary}>
							No outstanding invoices
						</SbTypography>
					)}
					{props.invoices.length !== 0 && (
						<SbTypography variant={"cardButton2"} color={theme.palette.text.secondary}>
							Here you can view your payment summary and pay any outstanding invoices directly
						</SbTypography>
					)}
					
					{props.invoices.map((invoice, index) => (
						<SbInvoiceCard key={index}
							invoice={invoice}
							isSelectable={false}
							cardContainerStyle={{height: "auto", width: "100%", borderColor: grey[300]}}
							buttonStyle={{padding: "8px 16px 8px 16px"}}
						/>
					))}
				</Stack>
			</CardActions>
		</Card>
	</TranslationProvider>;
}
