import React, {ReactNode, useState} from "react";
import {
	authorizedByLogin,
	Building01Icon,
	ExampleComponents,
	SbChip,
	SbEllipse,
	SbIconAvatar,
	SbLicensePlate,
	SbTab,
	SbTabId,
	SbTextField,
	SbTypographyRaw
} from "@surebase/shared-component-library";
import {
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	useTheme
} from "@mui/material";
import {useSearchParams} from "react-router-dom";

const tabParam = "tab";

export const ExamplePage = authorizedByLogin(() => {
	const [params, setParams] = useSearchParams();
	
	const activeTab = params.get(tabParam) ?? undefined;
	function setActiveTab(id: SbTabId) {
		params.set(tabParam, id.toString());
		setParams(params);
	}
	
	return <div style={{height: "100%", overflowY: "hidden"}}>
		<ExampleComponents
			tabs={[...getTabs()]}
			activeTab={activeTab}
			setActiveTab={setActiveTab}
		/>
	</div>;
});

function* getTabs(): Generator<SbTab, void> {
	yield tab("special-components", "SpecialComponents", <SpecialComponents/>);
}

function tab(id: string, header: ReactNode, children: ReactNode): SbTab {
	return {id, header, children};
}

function SpecialComponents() {
	return <Stack gap={3} style={{overflowY: "auto", paddingBottom: "20vh"}}>
		<LicensePlates/>
		<Ellipses/>
		<DotChips/>
		<AvatarIcons/>
	</Stack>;
}

function LicensePlates() {
	const values = [
		"GL973V",
		"gl973v",
		"GL-973-V",
		"GL 973 V",
		"KT378L",
		"01HTC8",
	];
	const [interactive, setInteractive] = useState("1abc2");
	
	return <Stack gap={1} id={"license-plate"}>
		<SbTypographyRaw variant={"header2"} tag={"license-plate"}>License Plate</SbTypographyRaw>
		<Table style={{width: "fit-content"}}>
			<TableBody>
				<TableRow>
					<TableCell><SbTypographyRaw>Value</SbTypographyRaw></TableCell>
					{values.map(licensePlate => {
						return <TableCell key={licensePlate}><SbTypographyRaw>{licensePlate}</SbTypographyRaw></TableCell>;
					})}
					<TableCell>
						<SbTextField
							FieldProps={{
								size: "small"
							}}
							label={"Custom"}
							value={interactive}
							onChange={setInteractive}
						/>
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell><SbTypographyRaw>Rendered</SbTypographyRaw></TableCell>
					{values.map(licensePlate => {
						return <TableCell key={licensePlate}><SbLicensePlate licensePlate={licensePlate}/></TableCell>;
					})}
					<TableCell><SbLicensePlate licensePlate={interactive}/></TableCell>
				</TableRow>
			</TableBody>
		</Table>
	</Stack>;
}

function Ellipses() {
	const colors = [
		undefined,
		"primary",
		"secondary",
		"success",
		"warning",
		"error",
		"info",
		"neutral",
	];
	
	return <Stack gap={1} id={"ellipse"}>
		<SbTypographyRaw variant={"header2"} tag={"ellipse"}>Ellipse</SbTypographyRaw>
		<Table style={{width: "fit-content"}}>
			<TableBody>
				<TableRow>
					<TableCell><SbTypographyRaw>Color</SbTypographyRaw></TableCell>
					{colors.map(color => {
						return <TableCell key={color ?? "default"}><SbTypographyRaw>{color ?? "default"}</SbTypographyRaw></TableCell>;
					})}
				</TableRow>
				<TableRow>
					<TableCell><SbTypographyRaw>Rendered</SbTypographyRaw></TableCell>
					{colors.map(color => {
						return <TableCell key={color ?? "default"}><SbEllipse color={color}/></TableCell>;
					})}
				</TableRow>
			</TableBody>
		</Table>
	</Stack>;
}

function DotChips() {
	const colors = [
		undefined,
		"primary",
		"secondary",
		"success",
		"warning",
		"error",
		"info",
		"neutral",
	];
	
	return <Stack gap={1} id={"ellipse-chips"}>
		<SbTypographyRaw variant={"header2"} tag={"ellipse-chips"}>Ellipse Chips (SB)</SbTypographyRaw>
		<Table style={{width: "fit-content"}}>
			<TableBody>
				<TableRow>
					<TableCell><SbTypographyRaw>Color</SbTypographyRaw></TableCell>
					{colors.map(color => {
						return <TableCell key={color ?? "default"}><SbTypographyRaw>{color ?? "default"}</SbTypographyRaw></TableCell>;
					})}
				</TableRow>
				<TableRow>
					<TableCell><SbTypographyRaw>Rendered</SbTypographyRaw></TableCell>
					{colors.map(color => {
						return <TableCell key={color ?? "default"}>
							<SbChip color={color} size={"small"}>
								<Stack direction={"row"} gap={"6px"}>
									<SbEllipse color={color}/>
									<SbTypographyRaw>{color ?? "default"}</SbTypographyRaw>
								</Stack>
							</SbChip>
						</TableCell>;
					})}
				</TableRow>
			</TableBody>
		</Table>
	</Stack>;
}

function AvatarIcons() {
	const colors = [
		undefined,
		"primary",
		"secondary",
		"success",
		"warning",
		"error",
		"info",
		"neutral",
	];
	
	const theme = useTheme();
	const palette: any = theme.palette;
	
	return <Stack gap={1} id={"avatar-icons"}>
		<SbTypographyRaw variant={"header2"} tag={"avatar-icons"}>Avatar Icons</SbTypographyRaw>
		<Table style={{width: "fit-content"}}>
			<TableBody>
				<TableRow>
					<TableCell><SbTypographyRaw>Color</SbTypographyRaw></TableCell>
					{colors.map(color => {
						return <TableCell key={color ?? "default"}><SbTypographyRaw>{color ?? "default"}</SbTypographyRaw></TableCell>;
					})}
				</TableRow>
				<TableRow>
					<TableCell><SbTypographyRaw>Rendered</SbTypographyRaw></TableCell>
					{colors.map(color => {
						return <TableCell key={color ?? "default"}>
							<SbIconAvatar
								tooltip={true}
								avatarProps={{
									style: {
										height: "36px",
										width: "36px",
										border: color ? `2px solid ${palette[color].main}` : undefined,
										backgroundColor: theme.palette.background.paper
									},
									alt: "helper text"
								}}
							>
								<Building01Icon color={theme.palette.text.primary}/>
							</SbIconAvatar>
						</TableCell>;
					})}
				</TableRow>
			</TableBody>
		</Table>
	</Stack>;
}
