import React from "react";
import {Card, IconButton, Stack} from "@mui/material";
import {
	File03Icon, formatter, openNewTab, parseLocalDateTime,
	SbProcessButton, SbTypography,
	SbTypographyRaw, sharedGraphQlServices, Trash02Icon
} from "@surebase/shared-component-library";
import ReactHtmlParser from "html-react-parser";
import {GET_MESSAGES_LINK_PRE_ASSIGNED_URL} from "../../../services/messages/MessagesQueries";
import {Messages} from "../../../services/messages/Messages";
import {format} from "../../../utils/Formatting";

export interface MessageViewProps {
	readonly message: Messages.Message | undefined;
	readonly onRemoved: (message: Messages.Message) => void;
}

export function MessageView(props: MessageViewProps) {
	async function openLink(messageId: string, link: Messages.Link) {
		const response = await sharedGraphQlServices.messageBoxService.query<Messages.Queries>({
			query: GET_MESSAGES_LINK_PRE_ASSIGNED_URL,
			variables: {messageId: messageId, linkId: link.id}
		});
		
		const url = response.data.messagesLinkPreAssignedUrl?.downloadUrl;
		if (!url)
			throw new Error("Could not generate preassinged url");
		
		openNewTab(url);
	}
	
	if (!props.message)
		return <Card style={{flexGrow: "1"}}>Nothing</Card>;
	
	const message = props.message;
	const links = message.links ?? [];
	
	const messageCategory = format.enum(message.messageCategory);
	return <Card style={{flexGrow: "1", padding: "24px 32px", borderRadius: "15px", alignSelf: "start"}}>
		<Stack flexWrap={"wrap"} justifyContent={"flex-start"} gap={3}>
			<Stack direction={"row"} gap={2} alignItems={"center"}>
				<File03Icon color={"primary"}/>
				<Stack flexGrow={1}>
					<SbTypographyRaw variant={"header3"}>{message.senderPlatform}</SbTypographyRaw>
					<SbTypography>{messageCategory}</SbTypography>
				</Stack>
				<Stack direction={"row"} gap={1} alignItems={"center"}>
					<SbTypographyRaw
						variant={"header3"}
					>
						{formatter.dateTimeShort(
							parseLocalDateTime(message.creationDateTime),
							{month: "short"}
						)}
					</SbTypographyRaw>
					<IconButton onClick={() => {
						props.onRemoved(message);
					}}><Trash02Icon/>
					</IconButton>
				</Stack>
			
			</Stack>
			<SbTypographyRaw variant={"header2"}>{message.header}</SbTypographyRaw>
			<SbTypographyRaw>{message.subject}</SbTypographyRaw>
			<SbTypographyRaw>{ReactHtmlParser(message.body)}</SbTypographyRaw>
			{links.length > 0 && <div>
				{links.map(link => (
					<SbProcessButton
						LoadingButtonProps={{
							variant: "contained",
							onClick: () => openLink(message.id, link)
						}}
						key={link.name}
						defaultContent={link.name}
						completedContent={link.name}
						inProgressContent={"Loading..."}
					></SbProcessButton>
				))}
			</div>}
		</Stack>
	</Card>;
}
