
class Formatting {
	/*
	Convert a enum to readable text i.e. "SYSTEM_NOTIFICATION" to "System Notification"
	*/
	enum(input: string): string {
		let result = input.toLowerCase().replace(/(_\w)/g, match => " " + match[1].toUpperCase());
		result = result.charAt(0).toUpperCase() + result.slice(1);
		return result;
	}
}

export const format = new Formatting();
