import React, {useState} from "react";
import {
	DotsVerticalIcon,
	fullHeight,
	SbTypography
} from "@surebase/shared-component-library";
import {CallToAction, NavigationAction} from "../models/calltoaction/CallToAction";
import {IconButton, Stack} from "@mui/material";
import {SbCallToActionList} from "../components/common/SbCallToActionList";

export function HomePage() {
	const [callToActions, setCallToActions] = useState<CallToAction[]>(() => [
		{
			type: "navigation",
			name: "Customers",
			icon: "",
			url: "/customers"
		} as NavigationAction,
		{
			type: "navigation",
			name: "Contracts",
			icon: "",
			url: "/contracts"
		} as NavigationAction,
		{
			type: "navigation",
			name: "Lorem ipsum",
			icon: "CoinsStacked04Icon",
			url: "/claims"
		} as NavigationAction,
	]);
	
	return <div style={{...fullHeight, paddingBottom: "30px"}}>
		<Stack gap={3} style={{...fullHeight}}>
			<Stack direction={"row"} justifyContent={"space-between"}>
				<SbTypography variant={"header2"}>Favorites</SbTypography>
				<IconButton>
					<DotsVerticalIcon/>
				</IconButton>
			</Stack>
			
			<SbCallToActionList items={callToActions} setItems={setCallToActions} />
			
			<Stack direction={"row"} justifyContent={"space-between"}>
				<SbTypography variant={"header2"}>News feed</SbTypography>
				<IconButton>
					<DotsVerticalIcon/>
				</IconButton>
			</Stack>
			
			{/*<SbMainRight></SbMainRight>*/}
			
		</Stack>
	</div>;
}
