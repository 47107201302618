import React, {useState} from "react";
import {CallToAction} from "../../models/calltoaction/CallToAction";
import {SbCallToAction, SbCallToActionNew} from "./SbCallToAction";
import {
	Autocomplete,
	Button,
	Dialog,
	DialogContent,
	DialogTitle, ListSubheader,
	MenuItem,
	Stack,
	TextField, Typography,
	useMediaQuery,
	useTheme
} from "@mui/material";
import {SbIconProps, SbTextField, SbTypography, SbTypographyRaw} from "@surebase/shared-component-library";
import * as Icons from "@surebase/shared-component-library";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import { List } from "react-virtualized";

const LISTBOX_PADDING = 8; // px

function random(length: number) {
	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';
	
	for (let i = 0; i < length; i += 1) {
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}
	
	return result;
}

const OPTIONS = Array.from(new Array(10000))
	.map(() => random(10 + Math.ceil(Math.random() * 20)))
	.sort((a: string, b: string) => a.toUpperCase().localeCompare(b.toUpperCase()));

export interface SbCallToActionListProps {
	readonly items: CallToAction[];
	readonly setItems: (items: CallToAction[]) => void;
}

export function SbCallToActionList(props: SbCallToActionListProps) {
	const [open, setOpen] = useState(false);
	const [name, setName] = useState<string | null>(null);
	const [url, setUrl] = useState<string | null>(null);
	const [icon, setIcon] = useState<string | null>(null);
	
	const icons = Object.entries(Icons)
		.filter(([key]) => key.endsWith("Icon"));
	const iconKeys = icons.map(([key]) => key);
	const iconProps: SbIconProps = {
		color: "primary",
		width: 20,
	};
	
	function renderRow(props: ListChildComponentProps) {
		const { data, index, style } = props;
		const dataSet = data[index];
		const inlineStyle = {
			...style,
			top: (style.top as number) + LISTBOX_PADDING,
		};

		// // return dataSet;
		// return <div style={inlineStyle}>
		// 	{dataSet}
		// </div>;
		
		// eslint-disable-next-line no-prototype-builtins
		if (dataSet.hasOwnProperty('group')) {
			return (
				<ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
					{dataSet.group} (2)
				</ListSubheader>
			);
		}

		const { key, ...optionProps } = dataSet[0];

		return (
			<Typography key={key} component="li" {...optionProps} noWrap style={inlineStyle}>
				{(Icons as any)[dataSet[1]](iconProps)} {dataSet[1]}
			</Typography>
		);
	}
	
	const OuterElementContext = React.createContext({});
	
	const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
		const outerProps = React.useContext(OuterElementContext);
		return <div ref={ref} {...props} {...outerProps} />;
	});
	
	function useResetCache(data: any) {
		const ref = React.useRef<VariableSizeList>(null);
		React.useEffect(() => {
			if (ref.current != null) {
				ref.current.resetAfterIndex(0, true);
			}
		}, [data]);
		return ref;
	}

	// Adapter for react-window
	const ListboxComponent = React.forwardRef<
		HTMLDivElement,
		React.HTMLAttributes<HTMLElement>
	>(function ListboxComponent(props, ref) {
		const { children, ...other } = props;
		const itemData: React.ReactElement[] = [];
		(children as React.ReactElement[]).forEach(
			(item: React.ReactElement & { children?: React.ReactElement[] }) => {
				itemData.push(item);
				itemData.push(...(item.children || []));
			},
		);
		
		const theme = useTheme();
		const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
			noSsr: true,
		});
		const itemCount = itemData.length;
		const itemSize = smUp ? 36 : 48;
		
		const getChildSize = (child: React.ReactElement) => {
			// eslint-disable-next-line no-prototype-builtins
			if (child.hasOwnProperty('group')) {
				return 48;
			}
			
			return itemSize;
		};
		
		const getHeight = () => {
			if (itemCount > 8) {
				return 8 * itemSize;
			}
			return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
		};
		
		const gridRef = useResetCache(itemCount);
		
		return (
			<div ref={ref}>
				<OuterElementContext.Provider value={other}>
					<VariableSizeList
						itemData={itemData}
						height={getHeight() + 2 * LISTBOX_PADDING}
						width="100%"
						ref={gridRef}
						outerElementType={OuterElementType}
						innerElementType="ul"
						itemSize={(index) => getChildSize(itemData[index])}
						overscanCount={5}
						itemCount={itemCount}
					>
						{renderRow}
					</VariableSizeList>
				</OuterElementContext.Provider>
			</div>
		);
	});
	
	const ListboxComponent2 = React.forwardRef(function ListboxComponent(
		props: any,
		ref: any
	) {
		const { children, role, ...other } = props;
		const itemCount = Array.isArray(children) ? children.length : 0;
		const itemSize = 36;
		
		return (
			<div ref={ref}>
				<div {...other}>
					<List
						height={250}
						width={300}
						rowHeight={itemSize}
						overscanCount={5}
						rowCount={itemCount}
						rowRenderer={props => {
							return React.cloneElement(children[props.index], {
								style: props.style
							});
						}}
						role={role}
					/>
				</div>
			</div>
		);
	});
	
	return <div>
		<div style={{
			display: "grid",
			gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
			gap: "24px",
		}}>
			{props.items.map(it => <SbCallToAction key={JSON.stringify(it)} data={it}/>)}
			<SbCallToActionNew onClick={() => setOpen(true)}/>
		</div>
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DialogTitle>
				<SbTypography>Add shortcut</SbTypography>
			</DialogTitle>
			<DialogContent style={{minWidth: "400px"}}>
				<Stack gap={1}>
					<SbTypography>todo</SbTypography>
					{/*<SbTextField*/}
					{/*	label={"Name"}*/}
					{/*	value={name}*/}
					{/*	onChange={setName}*/}
					{/*/>*/}
					{/*<SbTextField*/}
					{/*	label={"Url"}*/}
					{/*	value={url}*/}
					{/*	onChange={setUrl}*/}
					{/*/>*/}
					
					{/*<Autocomplete<string>*/}
					{/*	value={icon}*/}
					{/*	options={iconKeys}*/}
					{/*	// isOptionEqualToValue={(option, value) => option === value}*/}
					{/*	renderInput={(params) => <TextField*/}
					{/*		{...params}*/}
					{/*		label={"Icon"}*/}
					{/*	/>}*/}
					{/*	// onChange={(_, item) => setIcon(item)}*/}
					{/*	onChange={(_, item) => console.log(item)}*/}
					{/*	autoHighlight*/}
					{/*	selectOnFocus*/}
					{/*	autoFocus*/}
					{/*	// disableListWrap*/}
					{/*	ListboxComponent={ListboxComponent}*/}
					{/*	// renderOption={(props, option, state) =>*/}
					{/*	// 	[props, option, state.index] as React.ReactNode*/}
					{/*	// }*/}
					{/*	// getOptionLabel={(option) => option}*/}
					{/*	renderOption={(_, option) => {*/}
					{/*		if (!option)*/}
					{/*			return <MenuItem key={"null"} value={""} hidden style={{display: "none"}}/>;*/}
					{/*		return <MenuItem key={option} value={option}>*/}
					{/*			<Stack direction={"row"} gap={1} alignItems={"center"}>*/}
					{/*				{(Icons as any)[option](iconProps)}*/}
					{/*				<SbTypographyRaw>{option}</SbTypographyRaw>*/}
					{/*			</Stack>*/}
					{/*		</MenuItem>;*/}
					{/*	}}*/}
					{/*/>*/}
					
					{/*<Autocomplete<string>*/}
					{/*	value={icon}*/}
					{/*	options={iconKeys}*/}
					{/*	// isOptionEqualToValue={(option, value) => option === value}*/}
					{/*	renderInput={(params) => <TextField*/}
					{/*		{...params}*/}
					{/*		label={"Icon"}*/}
					{/*	/>}*/}
					{/*	onChange={(_, item) => setIcon(item)}*/}
					{/*	// onChange={(_, item) => console.log(item)}*/}
					{/*	autoHighlight*/}
					{/*	selectOnFocus*/}
					{/*	autoFocus*/}
					{/*	// disableListWrap*/}
					{/*	ListboxComponent={ListboxComponent}*/}
					{/*	renderOption={(props, option, state) =>*/}
					{/*		[props, option, state.index] as React.ReactNode*/}
					{/*	}*/}
					{/*	// getOptionLabel={(option) => option}*/}
					{/*	// renderOption={(_, option) => {*/}
					{/*	// 	if (!option)*/}
					{/*	// 		return <MenuItem key={"null"} value={""} hidden style={{display: "none"}}/>;*/}
					{/*	// 	return <MenuItem key={option} value={option}>*/}
					{/*	// 		<Stack direction={"row"} gap={1} alignItems={"center"}>*/}
					{/*	// 			{(Icons as any)[option](iconProps)}*/}
					{/*	// 			<SbTypographyRaw>{option}</SbTypographyRaw>*/}
					{/*	// 		</Stack>*/}
					{/*	// 	</MenuItem>;*/}
					{/*	// }}*/}
					{/*/>*/}
					
					{/*<Autocomplete<string>*/}
					{/*	value={icon ?? ""}*/}
					{/*	options={["", ...iconKeys.slice(0, 100)]}*/}
					{/*	isOptionEqualToValue={(option, value) => option === value}*/}
					{/*	renderInput={(params) => <TextField*/}
					{/*		{...params}*/}
					{/*		label={"Icon"}*/}
					{/*	/>}*/}
					{/*	onChange={(_, item) => setIcon(item)}*/}
					{/*	autoHighlight*/}
					{/*	selectOnFocus*/}
					{/*	autoFocus*/}
					{/*	// disableListWrap*/}
					{/*	getOptionLabel={(option) => option}*/}
					{/*	renderOption={(_, option) => {*/}
					{/*		if (!option)*/}
					{/*			return <MenuItem key={"null"} value={""} hidden style={{display: "none"}}/>;*/}
					{/*		return <MenuItem key={option} value={option}>*/}
					{/*			<Stack direction={"row"} gap={1} alignItems={"center"}>*/}
					{/*				{(Icons as any)[option](iconProps)}*/}
					{/*				<SbTypographyRaw>{option}</SbTypographyRaw>*/}
					{/*			</Stack>*/}
					{/*		</MenuItem>;*/}
					{/*	}}*/}
					{/*/>*/}
					
					{/*<SbTextField*/}
					{/*	label={"Icon"}*/}
					{/*	value={icon ?? ""}*/}
					{/*	onChange={setIcon}*/}
					{/*	variant={"outlined"}*/}
					{/*	options={["", ...iconKeys.slice(0, 100)]}*/}
					{/*	renderOption={option => {*/}
					{/*		if (!option)*/}
					{/*			return <MenuItem key={"null"} value={""} hidden style={{display: "none"}}/>;*/}
					{/*		return <MenuItem key={option} value={option}>*/}
					{/*			<Stack direction={"row"} gap={1} alignItems={"center"}>*/}
					{/*				{(Icons as any)[option](iconProps)}*/}
					{/*				<SbTypographyRaw>{option}</SbTypographyRaw>*/}
					{/*			</Stack>*/}
					{/*		</MenuItem>;*/}
					{/*	}}*/}
					{/*/>*/}
					
					{/*<ExampleAutocomplete/>*/}
					
					{/*<IconSelector*/}
					{/*	options={iconKeys}*/}
					{/*	iconProps={iconProps}*/}
					{/*/>*/}
					
					{/*<Autocomplete*/}
					{/*	id="virtualize-demo"*/}
					{/*	value={icon}*/}
					{/*	onChange={(_, item) => setIcon(item)}*/}
					{/*	style={{ width: 300 }}*/}
					{/*	disableListWrap*/}
					{/*	ListboxComponent={ListboxComponent2}*/}
					{/*	options={iconKeys}*/}
					{/*	renderInput={params => (*/}
					{/*		<TextField*/}
					{/*			{...params}*/}
					{/*			variant="outlined"*/}
					{/*			label="10,000 options"*/}
					{/*			fullWidth*/}
					{/*		/>*/}
					{/*	)}*/}
					{/*	renderOption={(_, option) => {*/}
					{/*		if (!option)*/}
					{/*			return <MenuItem key={"null"} value={""} hidden style={{display: "none"}}/>;*/}
					{/*		return <MenuItem key={option} value={option}>*/}
					{/*			<Stack direction={"row"} gap={1} alignItems={"center"}>*/}
					{/*				{(Icons as any)[option](iconProps)}*/}
					{/*				<SbTypographyRaw>{option}</SbTypographyRaw>*/}
					{/*			</Stack>*/}
					{/*		</MenuItem>;*/}
					{/*	}}*/}
					{/*/>*/}
					
					<Button
						variant={"contained"}
						onClick={() => console.log("create")}
					>
						<SbTypography>Add</SbTypography>
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	</div>;
}

interface IconSelectorProps {
	readonly options: string[];
	readonly iconProps: SbIconProps;
}

function IconSelector(props: IconSelectorProps) {
	const [value, setValue] = useState<string | null>(null);
	
	return <Autocomplete<string>
		value={value}
		options={["", ...props.options]}
		isOptionEqualToValue={(option, value) => option === value}
		renderInput={(params) => <TextField
			{...params}
			label={"Icon"}
			InputProps={{
				...params.InputProps,
				startAdornment: value ? (Icons as any)[value](props.iconProps) : undefined
			}}
		/>}
		onChange={(_, item) => setValue(item)}
		autoHighlight
		selectOnFocus
		autoFocus
		// disableListWrap
		getOptionLabel={(option) => option}
		renderOption={(optionProps, option) => {
			if (!option)
				return <MenuItem {...optionProps} key={"null"} value={""} hidden style={{display: "none"}}/>;
			return <MenuItem {...optionProps} key={option} value={option}>
				<Stack direction={"row"} gap={1} alignItems={"center"}>
					{(Icons as any)[option](props.iconProps)}
					<SbTypographyRaw>{option}</SbTypographyRaw>
				</Stack>
			</MenuItem>;
		}}
	/>
}

function ExampleAutocomplete() {
	const options: AcOption[] = [
		{code: "code-1", description: "description 1"},
		{code: "code-2", description: "description 2"},
		{code: "code-3", description: "description 3"},
		{code: "code-4", description: "description 4"},
		{code: "code-5", description: "description 5"},
	];
	
	const [value, setValue] = useState<AcOption>();
	
	return <Autocomplete
		id="ac-field-id"
		autoComplete={true}
		options={options}
		filterSelectedOptions={true}
		groupBy={(option) => option?.code?.[0] || ''}
		isOptionEqualToValue={(option: AcOption, value: AcOption) => option.code === value.code}
		// onInputChange={(event, newInputValue) => {
		// 	setInputAcOption(newInputValue);
		// }}
		onChange={(_, it) => setValue(it ?? undefined)}
		value={value ?? null}
		filterOptions={(x) => x}
		getOptionLabel={(option: AcOption) => option?.code || ''}
		renderOption={(props, option: AcOption) => (
			<li {...props}>{option.code}&nbsp;&nbsp;&nbsp;{option.description}</li>
		)}
		renderInput={(params) => (
			<TextField {...params} label="AC Option" />
		)}
	/>;
}

interface AcOption {code: string; description: string}
