import React, {ReactElement, useState} from "react";
import {
	authorizedByLogin,
	File03Icon, PageInfo,
	SbBreadcrumbs, SbTypography, sharedGraphQlServices,
	TranslationProvider, userSessionProvider
} from "@surebase/shared-component-library";
import {Box, CircularProgress, Stack} from "@mui/material";
import {MessageList} from "./components/MessageList";
import {MessageView} from "./components/MessageView";
import {ApolloQueryResult, useQuery, useSubscription} from "@apollo/client";
import {Messages} from "../../services/messages/Messages";
import {Instant} from "@js-joda/core";
import {
	DELETE_MESSAGES,
	GET_MESSAGES_FOR_USER,
	MARK_AS_READ,
	SUBSCRIBE_MESSAGES
} from "../../services/messages/MessagesQueries";

export const MessageBoxPage = authorizedByLogin(function (): ReactElement {
	const [selectedMessage, setSelectedMessage] = useState<Messages.Message>();
	const pageSize = 50;
	const [messages, setMessages] = useState<Messages.Message[] | null>();
	const [messagesPageInfo, setMessagesPageInfo] = useState<PageInfo>();
	const messagesQuery = useQuery<Messages.Queries>(GET_MESSAGES_FOR_USER, {
		client: sharedGraphQlServices.messageBoxService,
		fetchPolicy: "no-cache",
		variables: {
			pageSize: pageSize
		}
	});
	
	function changeSelectedMessage(message: Messages.Message) {
		setSelectedMessage(message);
	}
	
	useSubscription(SUBSCRIBE_MESSAGES, {
		client: sharedGraphQlServices.messageBoxService,
		onError: (error) => {
			console.log(error, "ws error");
		},
		variables: {
			username: userSessionProvider.userSession?.user.userName
		},
		fetchPolicy: "no-cache",
		shouldResubscribe: true,
		onData({data}) {
			const msgs = data.data.newMessageBoxChange.messages as Messages.Message[];
			if (msgs) {
				const newDataSet = msgs.concat(messages ?? []);
				setMessages(newDataSet);
			}
		}
	});
	
	async function removeMessage(messageIdRemoved: string) {
		await sharedGraphQlServices.messageBoxService.query({
			query: DELETE_MESSAGES,
			variables: {input: {messageIds: [messageIdRemoved]}}
		});
		
		const newDataSet = Object.assign([], messages) as Messages.Message[];
		for (let i = newDataSet.length - 1; i >= 0; i--) {
			if (newDataSet[i].id === messageIdRemoved) {
				newDataSet.splice(i, 1);
				break;
			}
		}
		
		setMessages(newDataSet);
		setSelectedMessage(undefined);
	}
	
	if (messagesQuery.loading) {
		return <Box sx={{display: "flex", width: "100%", height: "100%"}}>
			<CircularProgress style={{margin: "auto"}}/>
		</Box>;
	}
	
	if (messagesQuery.error) {
		throw messagesQuery.error;
	}
	
	if (messagesQuery.data?.messagesForUser?.nodes && !messages) {
		setMessages(messagesQuery.data.messagesForUser.nodes);
		setMessagesPageInfo(messagesQuery.data.messagesForUser.pageInfo);
	}
	
	async function fetchMoreData() {
		if (!messagesPageInfo) {
			return;
		}
		const messagesQuery: ApolloQueryResult<Messages.Queries> = await sharedGraphQlServices.messageBoxService.query(
			{
				query: GET_MESSAGES_FOR_USER,
				variables: {
					pageSize: pageSize,
					cursorAfter: messagesPageInfo.endCursor
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (messagesQuery.data?.messagesForUser?.nodes) {
			const nodes = messagesQuery.data.messagesForUser.nodes;
			const newDataSet = Object.assign([], messages) as Messages.Message[];
			for (let i = 0; i < nodes.length; i++) {
				newDataSet.push(nodes[i]);
			}
			setMessages(newDataSet);
			setMessagesPageInfo(messagesQuery.data.messagesForUser.pageInfo);
		}
	}
	
	async function markAsRead(message: Messages.Message) {
		if (!message.readDateTime) {
			const now = Instant.now().toString();
			const newMessages = messages?.map(it => {
				if (it.id === message.id)
					return {...it, readDateTime: now};
				else
					return it;
			}) ?? [];
			setMessages(newMessages);
			
			await sharedGraphQlServices.messageBoxService.query<Messages.Queries>({
				query: MARK_AS_READ,
				variables: {input: {messageIds: [message.id]}}
			});
		}
	}
	
	return <TranslationProvider name={"SureBaseMain"}>
		<Stack gap={3} style={{height: "100%"}}>
			<Stack gap={2}>
				<SbBreadcrumbs/>
				<Stack direction={"row"} alignItems={"center"} gap={1}>
					<File03Icon color={"primary"}/>
					<SbTypography variant={"header2"} margin={"0"}>Message box</SbTypography>
				</Stack>
			</Stack>
			<Stack direction={"row"} style={{flexGrow: "1", height: "1px"}} gap={4}>
				<Stack>
					<MessageList
						onClick={(message) => changeSelectedMessage(message)}
						markAsRead={markAsRead}
						messages={messages || []}
						fetchMoreData={fetchMoreData}
						messagesPageInfo={messagesPageInfo}
						key={"mainList"}
					/>
				</Stack>
				{selectedMessage && <Stack style={{height: "fit-content"}}>
					<MessageView message={selectedMessage} onRemoved={async (dat) => {
						await removeMessage(dat.id);
					}}
					/></Stack>}
			</Stack>
		</Stack>
	</TranslationProvider>;
});
