import {graphQlServices} from "../../GraphQlServices";
import {Invoices} from "./Invoices";
import {GET_INVOICES} from "./InvoicesQueries";
import {ApolloQueryResult} from "@apollo/client";

export class InvoicesDataRepository {
	static async getOpenInvoices(customerId: string, sortOrder: "ASC" | "DESC" = "DESC"): Promise<Invoices.Invoice[]> {
		const response = await graphQlServices.invoicesService.query<Invoices.Queries>(
			{
				query: GET_INVOICES,
				variables: {
					first: 3,
					where: {
						and: [
							{amountOutstandingInvoice: {gt: 0.000001}},
							{
								party: {
									some: {
										and: [
											{
												refKey: {eq: customerId}
											},
											{
												entityType: {eq: "customer"}
											}
										]
									}
								}
							}
						]
					},
					order: [
						{bookingDate: sortOrder},
					]
				},
				fetchPolicy: "no-cache"
			}
		);
		
		if (response.error)
			throw response.error;
		
		return response.data?.invoices?.nodes ?? [];
	}
	
	static async getInvoices(customerId: string): Promise<Invoices.Invoice[]> {
		let allInvoices: Invoices.Invoice[] = [];
		let hasNextPage = true;
		let afterCursor: string | null = null;
		while (hasNextPage) {
			const response: ApolloQueryResult<Invoices.Queries> = await graphQlServices.invoicesService.query<Invoices.Queries>(
				{
					query: GET_INVOICES,
					variables: {
						first: 50,
						after: afterCursor,
						where: {
							party: {
								some: {
									refKey: {eq: customerId}
								}
							}
						},
						order: [{bookingDate: "DESC"}]
					},
					fetchPolicy: "no-cache"
				}
			);
			
			console.log("GET_INVOICES response:", response);
			
			if (response.error)
				throw response.error;
			
			const invoices = response.data?.invoices?.nodes ?? [];
			allInvoices = allInvoices.concat(invoices);
			hasNextPage = response.data?.invoices?.pageInfo?.hasNextPage ?? false;
			afterCursor = response.data?.invoices?.pageInfo?.endCursor ?? null;
		}
		
		return allInvoices;
	}
}
