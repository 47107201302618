import {gql} from "@apollo/client";

export const queryDossiers = gql`
	query GetDossiers($first: Int
		$after: String
		$where: DossierFilterInput
		$order: [DossierSortInput!]) {
		dossiers (first: $first, after: $after, where: $where, order: $order){
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
			nodes {
				id
				attachmentType
				documentDescription
				effectiveDate
			}
		}
	}
`;

export const DOSSIER_FILE = gql`
	query fileByDossierId($id: UUID!) {
		fileByDossierId(id: $id) {
			fileName
			base64File
			contentType
		}
	}
`;
