import {
	generalError,
	SbRedirectSubliem,
} from "@surebase/shared-component-library";
import {ErrorBoundary} from "react-error-boundary";
import React from "react";

export function SubliemRedirect() {
	return <ErrorBoundary FallbackComponent={generalError}>
		<SbRedirectSubliem packageType={"SUBLIEM_BUSINESS"}/>
	</ErrorBoundary>;
}
