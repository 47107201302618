import {
	authorizedByPermission,
	fullWidth,
	SbGraphQlDataGrid,
	SbPageHeader,
	SbTypography,
	SearchSmIcon,
	TranslationProvider,
	useCustomColumn,
	useDateTimeColumn,
	useLinkIconColumn,
	useStringColumn
} from "@surebase/shared-component-library";
import {
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	useTheme
} from "@mui/material";
import {Settings} from "../../global/Settings";
import React from "react";
import {permissions} from "../../global/Permissions";
import {CalendarToday, CloudDownload} from "@mui/icons-material";
import { Dossiers } from "../../services/documents/Dossiers";
import {queryDossiers} from "../../services/documents/DossiersQueries";
import {graphQlServices} from "../../services/GraphQlServices";
import {
	GridActionsCellItem,
	GridColDef, GridFilterInputValueProps,
	GridToolbarContainer,
	GridToolbarDensitySelector
} from "@mui/x-data-grid-pro";
import { useParams } from "react-router-dom";
import {DossierHelper} from "../../helpers/DossierHelper";

export const DocumentsPage = authorizedByPermission(() => {
	const theme = useTheme();
	const id = useParams()["id"] ?? "";
	
	type E = Dossiers.Dossier;
	const columns: GridColDef<E>[] = [
		useStringColumn<E>(
			"id"
		),
		useCustomColumn<E, object>(
			"attachmentType",
			{
				type: "string",
				headerName: "Attachment type",
				flex: 0.5,
				renderCell: params =>
					<SbTypography variant={"body2DataGrid"}>
						{translateAdnByl(params.row.attachmentType)}
					</SbTypography>,
				filterable: true,
				filterOperators: [
					{
						label: "Is gelijk aan",
						value: "equals",
						getApplyFilterFn: (filterItem) => {
							if (!filterItem.value)
								return null;
							
							return (value) => {
								return value === filterItem.value;
							};
						},
						InputComponent: CustomFilterInputComponent
					}
				]
			}
		),
		useStringColumn<E>(
			"documentDescription",
			{
				headerName: "Document description",
				flex: 1
			}
		),
		useDateTimeColumn<E>(
			"effectiveDate",
			{
				headerName: "Effective date",
				flex: 1
			}
		),
		useLinkIconColumn<E>(
			() => "",
			{
				headerName: "Download",
				headerAlign: "center",
				align: "center",
				renderCell: (params) =>
					<GridActionsCellItem
						label={"Download"}
						icon={<CloudDownload/>}
						onClick={() => DossierHelper.downloadDossier(params.id as string)}
					/>
			}
		)
	];
	
	const customToolbar = () => {
		return <GridToolbarContainer style={{
			width: "100%",
			height: "32px",
			padding: "4px 0px 0px 4px",
			marginBottom: "24px",
			alignItems: "end",
			justifyContent: "space-between"
		}}>
			<GridToolbarDensitySelector slotProps={{
				button: {
					startIcon: <CalendarToday />,
					style: {
						color: theme.palette.text.primary,
					}
				}
			}}/>
			<OutlinedInput
				size={"small"}
				placeholder={"Search"}
				style={{
					backgroundColor: "#FFF"
				}}
				startAdornment={
					<InputAdornment position={"start"}>
						<SearchSmIcon />
					</InputAdornment>
				}
			/>
		</GridToolbarContainer>;
	};
	
	return <TranslationProvider name={Settings.languageModule}>
		<Stack gap={2} style={{...fullWidth}}>
			<Stack gap={1}>
				<SbPageHeader pageName={"Documents"} overviewRoute={"/"} disableBreadcrumbs={false}/>
				<SbTypography style={{
					fontWeight: "400",
					fontSize: "16px",
					lineHeight: "24px",
					letterSpacing: "0.15px",
					color: theme.palette.text.secondary
				}}>
					Lorem ipsum dolor sit amet consectetur. Diam sit fermentum nisl dictumst pellentesque mi duis ut.
				</SbTypography>
			</Stack>
			<SbGraphQlDataGrid<Dossiers.Queries, Dossiers.Dossier>
				query={queryDossiers}
				graphFunction={"dossiers"}
				client={graphQlServices.dossierServices}
				queryVariables={{ where: { party: { some: { refKey: { eq: id } } } } }}
				getRowId={it => it.id}
				initialPageSize={50}
				rowsPerPageOptions={[50]}
				columns={columns}
				gridHeight={500}
				initialState={{
					columns: {
						columnVisibilityModel: {
							id: false
						}
					}
				}}
				defaultSortModel={[
					{
						field: "effectiveDate",
						sort: "desc"
					}
				]}
				infiniteLoading={true}
				slots={{
					toolbar: customToolbar
				}}
			/>
		</Stack>
	</TranslationProvider>;
}, [permissions.dossiersRead]);

const translateAdnByl = (code: Dossiers.AdnByl | null) => {
	if (code === null)
		return "unknown value";
	
	const translation = AdnBylMap.get(code);
	
	if (!translation)
		console.log("Code", code);
	
	return translation ?? "unknown value";
};

const CustomFilterInputComponent = (props: GridFilterInputValueProps) => {
	const { item, applyValue } = props;
	
	return <FormControl fullWidth>
		<InputLabel id="demo-simple-select-label" style={{marginTop: "8px"}}>Waarde</InputLabel>
		<Select
			variant={"standard"}
			label="Waarde"
			value={item.value || ""}
			onChange={(event) => applyValue({ ...item, value: event.target.value })}
		>
			{[...AdnBylMap.entries()].map(([key, value]) => (
				<MenuItem key={key} value={key}>
					{value}
				</MenuItem>
			))}
		</Select>
	</FormControl>;
};

// TODO Keep up-to date with values in Dossiers DB
const AdnBylMap = new Map([
	["V12", "(Pakket) Polis"],
	["V16", "Brief"],
	["V19", "Boekingsnota"],
	["V27", "(Kopie) Factuur klant"],
	["V34", "Groene kaart"],
	["V35", "Polisbescheiden"],
	["V47", "Polisaanhangsel, Clausuleblad"],
	["V51", "Aanbiedingsbrief"],
	["V52", "Brief verlenging contract"],
	["V54", "(Kopie) Verzekeringsbewijs"],
	["V55", "(Kopie) Verzekeringsoverzicht"],
	["V69", "Brief betaalachterstand"],
	["V91", "Naverrekeningsformulier"],
	["V94", "Reispas"],
	["VB1", "Betalingsherinnering"],
	["VB3", "Tweede aanmaning"],
	["VF5", "SEPA incasso machtigingsformulier"],
]);
