import React from "react";
import ReactDOM from "react-dom/client";
import {Main} from "./main/components/onsite/Main";
import "react-toastify/dist/ReactToastify.css";
// initialize timezone data
// noinspection ES6UnusedImports
import {} from "@js-joda/timezone";

import {init} from "@surebase/shared-component-library";

init();

const container = document.createElement("div");
container.id = "root";
document.body.appendChild(container);
ReactDOM.createRoot(container).render(<Main/>);
