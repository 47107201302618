import {DownloadFileHelper} from "./DownloadFileHelper";
import {Dossiers} from "../services/documents/Dossiers";
import {DossiersDataRepository} from "../services/documents/DossiersDataRepository";

export class DossierHelper {
	static mimeTypes: Record<string, string> = {
		"txt": "text/plain",
		"html": "text/html",
		"css": "text/css",
		"js": "application/javascript",
		"json": "application/json",
		"csv": "text/csv",
		"xml": "application/xml",
		"pdf": "application/pdf",
		"jpeg": "image/jpeg",
		"jpg": "image/jpeg",
		"png": "image/png",
		"gif": "image/gif",
		"bmp": "image/bmp",
		"svg": "image/svg+xml",
		"webp": "image/webp",
		"mp3": "audio/mpeg",
		"wav": "audio/wav",
		"ogg": "audio/ogg",
		"mp4": "video/mp4",
		"webm": "video/webm",
		"avi": "video/x-msvideo",
		"zip": "application/zip",
		"gz": "application/gzip",
		"rar": "application/vnd.rar",
		"7z": "application/x-7z-compressed",
		"woff": "font/woff",
		"woff2": "font/woff2",
		"ttf": "font/ttf",
		"otf": "font/otf"
	};
	
	// TODO Show when download failed in UI
	static downloadDossier(dossierId: string): void {
		DossiersDataRepository.getDownloadUrlForDossier(dossierId).then((downloadFile: Dossiers.DownloadFile | null) => {
			if (!downloadFile)
				return;
			
			if (downloadFile.fileName === "" || downloadFile.base64File === "" || downloadFile.contentType === "")
				return;
			
			const bytes = DownloadFileHelper.base64ToArrayBuffer(downloadFile.base64File);
			const extension = downloadFile.fileName.split(".").pop()?.toLowerCase() || "";
			const mimeType = DossierHelper.mimeTypes[extension];
			
			if (mimeType) {
				const blob = new Blob([bytes],  {type: mimeType});
				const url = URL.createObjectURL(blob);
				window.open(url, "_blank");
				URL.revokeObjectURL(url);
			} else {
				DownloadFileHelper.saveByteArray(downloadFile.fileName, bytes, downloadFile.contentType);
			}
		});
	}
}
