import {Stack} from "@mui/material";
import {MessageListItem} from "./MessageListItem";
import InfiniteScroll from "react-infinite-scroll-component";
import {PageInfo, SbTypography} from "@surebase/shared-component-library";
import {Messages} from "../../../services/messages/Messages";

export interface MessageListProps {
	readonly onClick: (message: Messages.Message) => void;
	readonly fetchMoreData: () => Promise<void>;
	readonly messagesPageInfo: PageInfo | undefined;
	readonly messages: Messages.Message[];
	readonly markAsRead: (message: Messages.Message) => Promise<void>;
}

export function MessageList(props: MessageListProps) {
	const messages = props.messages;
	const messagesPageInfo = props.messagesPageInfo;
	return <div id="scrollableDiv"
		style={{
			overflowY: "auto",
			display: "flex",
			flexDirection: "column",
			paddingRight: "32px"
		}}>
		{messages.length === 0
			? <SbTypography>No messages</SbTypography>
			: <InfiniteScroll
				dataLength={messages?.length ?? 0}
				next={async () => {
					await props.fetchMoreData();
				}}
				style={{display: "flex", flexDirection: "column"}}
				hasMore={messagesPageInfo?.hasNextPage ?? false}
				loader={<SbTypography>Loading...</SbTypography>}
				scrollableTarget="scrollableDiv"
			>
				<Stack gap={2} style={{overflowY: "auto", width: "400px"}}>
					{messages && messages.map(message => {
						return <MessageListItem
							key={message.id}
							message={message}
							onClick={props.onClick}
							markAsRead={props.markAsRead}
						/>;
					})}
				</Stack>
			</InfiniteScroll>}
	</div>;
}
