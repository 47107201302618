import {
	generalError, SbRedirect,
} from "@surebase/shared-component-library";
import {ErrorBoundary} from "react-error-boundary";
import React from "react";

export function Redirect() {
	return <ErrorBoundary FallbackComponent={generalError}>
		<SbRedirect />
	</ErrorBoundary>;
}
