import {graphQlServices} from "../GraphQlServices";
import {DOSSIER_FILE} from "./DossiersQueries";
import {Dossiers} from "./Dossiers";

export class DossiersDataRepository {
	static async getDownloadUrlForDossier(dossierId: string): Promise<Dossiers.DownloadFile | null> {
		const response = await graphQlServices.dossierServices.query<Dossiers.Queries>(
			{
				query: DOSSIER_FILE,
				variables: {
					id: dossierId,
				},
				fetchPolicy: "no-cache",
			}
		);
		
		if (response.error)
			throw response.error;
		
		console.log("response", response);
		
		return response.data?.fileByDossierId;
	}
}
