import {Button, Card, Stack} from "@mui/material";
import React, {CSSProperties} from "react";
import {CallToAction, NavigationAction} from "../../models/calltoaction/CallToAction";
import {
	fullSize,
	SbLinkButton,
	SbTypography,
	palette, fullHeight, SbIconProps, PlusIcon
} from "@surebase/shared-component-library";
import * as Icons from "@surebase/shared-component-library";

export interface SbCallToActionProps {
	readonly data: CallToAction;
}

const minWidth = "350px";
const maxWidth = "450px";
const height = "93px";

// todo generalize
export function SbCallToAction(props: SbCallToActionProps) {
	const baseStyle: CSSProperties = {
		borderRadius: "8px",
		minWidth,
		maxWidth,
		height,
	};
	
	if (props.data.type === "navigation") {
		const action = props.data as NavigationAction;
		return <Card variant={"outlined"} style={baseStyle}>
			<SbLinkButton
				to={action.url}
				ButtonProps={{
					style: {
						...fullSize,
						borderRadius: "inherit",
						padding: "12px 24px",
						gap: "8px",
						justifyContent: "center",
						flexDirection: "column",
					}
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				><SbCallToActionIcon data={props.data}/></div>
				<SbTypography variant={"cardButtonHeader2"} color={theme => theme.palette.text.primary}>{props.data.name}</SbTypography>
			</SbLinkButton>
		</Card>;
	}
	
	return <Card style={baseStyle} variant={"outlined"}>
		<Stack direction={"row"} alignItems={"center"} style={{...fullSize, padding: "0 0 0 16px"}}>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			><SbCallToActionIcon data={props.data}/></div>
			<SbTypography variant={"cardButtonHeader2"}>{props.data.name}</SbTypography>
		</Stack>
	</Card>;
}

function SbCallToActionIcon(props: { data: CallToAction }) {
	const name = getIconName(props.data);
	const iconProps: SbIconProps = {
		color: "primary",
		width: 24,
	};
	return getIconByName(name)(iconProps);
}

function getIconByName(name: string, defaultName?: string): any {
	const func = (Icons as any)[name];
	if (func)
		return func;
	return (Icons as any)[defaultName || "FileQuestion01Icon"];
}

function getIconName(callToAction: CallToAction) {
	if (callToAction.icon)
		return callToAction.icon;
	
	if (callToAction.type === "navigation") {
		const action = callToAction as NavigationAction;
		switch (action.url) {
			case "/customers": return "Users02Icon";
			case "/contracts": return "File05Icon";
			case "/claims": return "ClipboardIcon";
		}
	}
	
	return "Users02Icon";
}

export interface SbCallToActionNewProps {
	readonly onClick: () => void;
}

export function SbCallToActionNew(props: SbCallToActionNewProps) {
	return <Button
		onClick={props.onClick}
		style={{
			minWidth,
			maxWidth,
			height,
			flex: "1 0 0px",
			borderRadius: "8px",
			border: "1px dashed var(--secondary-outline-border, rgba(56, 66, 80, 0.50))", // todo hardcoded color
			color: palette.passive.main
		}}
	>
		<Stack direction={"row"} alignItems={"center"} justifyContent={"space-around"} style={{...fullHeight}}>
			<PlusIcon width={24} color={"primary"}/>
		</Stack>
	</Button>;
}
