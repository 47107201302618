import React from "react";
import {Button, Card, Stack} from "@mui/material";
import {
	formatter,
	parseLocalDate,
	SbEllipse,
	SbTypography,
	SbTypographyRaw,
	typographyBodyMedium, typographyBodySmall
} from "@surebase/shared-component-library";
import {format} from "../../../utils/Formatting";
import {Messages} from "../../../services/messages/Messages";

export interface MessageItemProps {
	readonly message: Messages.Message;
	readonly onClick: (message: Messages.Message) => void;
	readonly markAsRead: (message: Messages.Message) => Promise<void>;
}

export function MessageListItem(props: MessageItemProps) {
	const cardStyle: React.CSSProperties = {width: "100%", minHeight: "137px", borderRadius: "10px"};
	
	const messageCategory = format.enum(props.message.messageCategory);
	
	return <Card style={cardStyle}>
		<Button onClick={async () => {
			await props.markAsRead(props.message);
			props.onClick(props.message);
		}} style={{
			borderRadius: "inherit",
			flexDirection: "column",
			padding: "0px",
			alignItems: "stretch",
			width: "100%"
		}}>
			<Stack direction={"row"} justifyContent={"space-between"} padding={"16px"}>
				<Stack direction={"row"} gap={1}>
					{!props.message.readDateTime &&
						
						<SbEllipse style={{margin: undefined, marginTop: "auto", marginBottom: "auto"}}/>
					}
					<SbTypographyRaw
						variant={"header2"}
						color={theme => theme.palette.text.primary}
						style={{
							textOverflow: "ellipsis",
							maxWidth: "300px",
							display: "inline-block",
							whiteSpace: "nowrap",
							overflow: "hidden"
						}}
					>{props.message.header}</SbTypographyRaw>
				</Stack>
				<SbTypographyRaw
					color={theme => theme.palette.text.primary} {...typographyBodyMedium}
				>
					{formatter.date(parseLocalDate(props.message.creationDateTime), {
						month: "short", year: undefined
					})}
				</SbTypographyRaw>
			</Stack>
			<div style={{padding: "16px", textAlign: "start"}}>
				<SbTypographyRaw
					color={theme => theme.palette.text.primary} {...typographyBodySmall}>{props.message.senderPlatform}&nbsp;</SbTypographyRaw>
				<SbTypography color={theme => theme.palette.text.primary} {...typographyBodySmall}>{messageCategory}</SbTypography>
				<SbTypographyRaw color={theme => theme.palette.text.primary} {...typographyBodySmall}>{props.message.subject}</SbTypographyRaw>
			</div>
		</Button>
	</Card>;
}
