export class DownloadFileHelper {
	
	static saveByteArray(reportName : string, byte: Uint8Array, contentType: string) {
		const blob = new Blob([byte], {type: contentType}); //"application/pdf"
		const link = document.createElement("a");
		link.href = window.URL.createObjectURL(blob);
		link.download = reportName;
		link.click();
	}
	
	
	static base64ToArrayBuffer(base64: string) {
		const binaryString = window.atob(base64);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		return bytes;
	}
}
